import React from "react";
import "./footer.css";
import PIER from "../../Assets/PIER.png";
import BRITISH from "../../Assets/british_council.png";
import { NavLink } from "react-router-dom";

import {
  FaYoutube,
  FaInstagram,
  FaLinkedinIn,
  FaSquareFacebook,
} from "react-icons/fa6";
import { IoMdCall, IoLogoWhatsapp } from "react-icons/io";
// import RazorpayBtn from "./Razorpay";
function Footer() {
  return (
    <div className="Footer_mainbox mb-0">
      <div className="footer_subsection d-flex flex-column flex-md-row">
        <div>
          <img src={PIER} alt="pier" className="pier_image rounded-2"></img>

          <div className="british-council rounded-2">
            {/* #23085A */}
            <img src={BRITISH} alt="british_council banner" />
            <p className="british_council_tag d-md-none d-xxl-block ">
              Education UK Certificate for Agents
            </p>
          </div>
        </div>

        <div>
          <h6 className="footer_heading">About Us</h6>
          <ul className="footer_links ps-0">
            <li>
              <NavLink to="/OurStory">Our Story</NavLink>
            </li>
            <li>
              <NavLink to="/Team">Our Team</NavLink>
            </li>
            <li>
              <NavLink to="/Careers">Jobs</NavLink>
            </li>
            <li>
              <a
                href="https://g.page/r/CdtR4ZNfQIPaEB0/review"
                target="_blank"
                rel="noreferrer"
              >
                Write a Review
              </a>
            </li>
            {/* <li>
              <NavLink to="/Services">Services</NavLink>
            </li> */}
          </ul>
        </div>

        <div>
          <h6 className="footer_heading">Legal Terms</h6>
          <ul className="footer_links ps-0">
            <li>
              <NavLink to="/PrivacyPolicy">Privacy Policy</NavLink>
            </li>
            <li>
              <NavLink to="/TermsAndConditions">Terms and Conditions</NavLink>
            </li>
            <li>
              <NavLink to="/payment-refund-policy">
                Payment and Refund Policy
              </NavLink>
            </li>
          </ul>
        </div>

        <div>
          <h6 className="footer_heading">Connect with Us</h6>
          <ul className="footer_links ps-0">
            <li className="text-white">Mon-Sun ( 9AM to 6PM)</li>
            <li>
              <a href="tel:+91 9513392468">
                <IoMdCall className="me-2" />
                9513392468
              </a>
            </li>
            <li className="d-md-none">
              <a href="https://wa.me/8186960181">
                <IoLogoWhatsapp className="me-2" />
                WhatsApp
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/confluencestudy/"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram className="me-2" />
                Instagram
              </a>
            </li>
            <li>
              <a
                href="https://in.linkedin.com/company/confluence-educational-services-pvt-ltd"
                target="_blank"
                rel="noreferrer"
              >
                <FaLinkedinIn className="me-2" />
                LinkedIn
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/groups/321180985026850/"
                target="_blank"
                rel="noreferrer"
              >
                <FaSquareFacebook className="me-2" />
                Facebook
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/@confluencestudy"
                target="_blank"
                rel="noreferrer"
              >
                <FaYoutube className="me-2" />
                Youtube
              </a>
            </li>
          </ul>
        </div>

        <div className="address_maindiv">
          <h6 className="footer_heading"> Address:</h6>
          <div>
            <p className="address">
              Confluence Educational Services Pvt Ltd
              <br />
              Flat no 302, 3rd floor
              <br />
              Sai Goverdhan Kunj, Main Road,
              <br />
              opp. Dominos Pizza
              <br />
              near Community Hall, <br />
              SR Nagar, Hyderabad, Telangana 500038
            </p>
          </div>
        </div>

        <div>
          <iframe
            title="Google map location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.364322325823!2d78.44365467591118!3d17.44226820122031!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb90c36aaaaaab%3A0xda83405f93e151db!2sConfluence%20Educational%20Services%20Private%20Limited!5e0!3m2!1sen!2sin!4v1702530862159!5m2!1sen!2sin"
            className="google_map"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>

      <div className="copyright_section text-center m-0 fw-light">
        {/* <RazorpayBtn /> */}
        <span className="copyright_symbol me-2"></span>
        2023 Confluence Educational Services Private Limited. All rights
        reserved.
      </div>
      <div className="m-0 d-flex justify-content-center">
        <p className="m-0 mb-1">
          <b>Developed By:</b>
          <a
            href="https://svsrco.com"
            target="_blank"
            rel="noreferrer"
            className="text-decoration-none"
          >
            &nbsp;&nbsp;SVSR Communications Pvt Ltd
          </a>
        </p>
      </div>
    </div>
  );
}

export default Footer;
