import React from "react";
import FeeStructureTables from "../FeeStructureTable";
import Services from "./Services.json";
import CanadaAtoV from "../../../Assets/countryImages/Canada A to V.jpg";
import "./countryImage.css";

function Canada() {
  const StudentServices = [
    {
      service: "Bachelors Admission Counseling Services",
      Fees: 30000,
      GST: 5400,
      Total: 35400,
    },
    {
      service: "Masters Admission Counseling Services",
      Fees: 25000,
      GST: 4500,
      Total: 29500,
    },
    {
      service: "Canada Universities Admission Counseling",
      Fees: 100000,
      GST: 18000,
      Total: 118000,
    },
    {
      service: "MBA in Canada Admissions",
      Fees: 100000,
      GST: 18000,
      Total: 118000,
    },
    {
      service: "Visit Visa Services",
      Fees: 30000,
      GST: 5400,
      Total: 35400,
    },
  ];

  return (
    <div className="bg-white d-flex justify-content-center flex-column align-items-center">
      {/* <h1>Canada</h1> */}
      <img
        src={CanadaAtoV}
        alt="Canada Admission to visa process"
        className="countryAtoVImg"
      />
      <FeeStructureTables
        countryName={"Canada"}
        studentServices={StudentServices}
      />
    </div>
  );
}

export default Canada;
