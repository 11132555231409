import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Modal } from "react-bootstrap";
import config from "../../Boot/config.json";
import { AuthContext } from "../../App";
import "./feeStructureTable.css";
import { FaPlus } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";

function FeeStructureTables({
  studentServices,
  nonStudentServices,
  countryName,
}) {
  const navigate = useNavigate();
  const { token } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [formShow, setFormShow] = useState(false);
  const [delStatus, setDelStatus] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [serviceFormData, setServiceFormData] = useState({
    country: countryName,
    serviceName: "",
    fee: 0,
    gst: 0,
    total: 0,
  });

  const handleClose = () => {
    setFormShow(false);
  };

  const handleDeleteTableInfo = (serviceId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this service?"
    );

    if (confirmDelete) {
      axios
        .delete(
          `${config.baseUrl}/CountryInfo/deleteService?serviceId=${serviceId}`,
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          setDelStatus(!delStatus);
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleServiceInfoChange = (e) => {
    const { id, value } = e.target;
    setServiceFormData((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      const { serviceName, fee, gst, total } = serviceFormData;

      if (!serviceName || !fee || !gst || !total) {
        alert("All fields are mandatory");
      } else {
        axios
          .post(
            `${config.baseUrl}/CountryInfo/addService`,
            { serviceFormData },
            {
              headers: {
                Authorization: token,
              },
            }
          )
          .then((res) => {
            console.log(res);
            setSubmitStatus(!submitStatus);
            setFormShow(false);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (error) {
      console.log("---err", error);
    }
  };

  const handleNavigateToService = (serviceId, serviceName, countryName) => {
    console.log(serviceId);
    navigate(
      `/${countryName.replace(/[\s/?]+/g, "-")}/${serviceName.replace(
        /[\s/?]+/g,
        "-"
      )}/${serviceId.slice(4)}`
    );

    console.log("--------id", serviceId.slice(4));
  };

  // useEffect(() => {
  //   if (data.serviceName) {
  //     navigate(
  //       `/${countryName.replace(
  //         /[\s/?]+/g,
  //         "-"
  //       )}/services/${data.serviceName.replace(/[\s/?]+/g, "-")}`,
  //       {
  //         state: {
  //           serviceType: data.serviceType,
  //           Name: data.serviceName,
  //         },
  //       }
  //     );
  //   }
  // }, [data, navigate, countryName]);

  useEffect(() => {
    axios
      .get(`${config.baseUrl}/CountryInfo/getAllServices`, {
        params: { country: countryName },
      })
      .then((res) => {
        console.log(res.data.services);
        setData(res.data.services);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [delStatus, submitStatus]);

  return (
    <div className="overflow-x-scroll tableContent m-sm-3 mb-sm-0 mb-0 d-flex justify-content-start justify-content-sm-center">
      <div>
        <div className="d-flex justify-content-center align-items-center">
          <h4 className="text-center countryPageHeading">Student Services</h4>
          {token && (
            <button
              className="border-0 ms-3 mb-2 p-2 rounded-2 text-white"
              style={{
                backgroundColor: "orange",
              }}
              onClick={() => {
                setFormShow(true);
              }}
              title="Add service"
            >
              <FaPlus />
            </button>
          )}
        </div>

        <table className="border border-secondary mb-5 mx-auto">
          <tr>
            <th className="p-2 border border-secondary">Service</th>
            {/* <th className="p-2 border border-secondary">Country</th> */}
            <th className="p-2 border border-secondary">Fees (₹)</th>
            <th className="p-2 border border-secondary">GST (₹)</th>
            <th className="p-2 border border-secondary">Total (₹)</th>

            <th className="p-2 border border-secondary d-none d-md-block">
              Know More
            </th>
          </tr>
          {data.map((service) => {
            return (
              <>
                <tr key={service._id}>
                  <td
                    className="border border-secondary p-2"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handleNavigateToService(
                        service.serviceId,
                        service.serviceName,
                        service.countryName
                      )
                    }
                  >
                    {service.serviceName}
                  </td>
                  {/* <td className="border border-secondary p-2">
                    {value.Country}
                  </td> */}
                  <td className="border border-secondary p-2">{service.fee}</td>
                  <td className="border border-secondary p-2">{service.gst}</td>
                  <td className="border border-secondary p-2">
                    {service.total}
                  </td>
                  <div className="d-flex">
                    <td
                      className="p-2 text-primary d-none d-md-flex border-top border-secondary"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handleNavigateToService(
                          service.serviceId,
                          service.serviceName,
                          service.countryName
                        )
                      }
                    >
                      Included Services
                    </td>
                    {token && (
                      <td
                        className="border border-secondary p-2 d-none d-md-block"
                        onClick={() => handleDeleteTableInfo(service._id)}
                        style={{ cursor: "pointer" }}
                        title="Delete Service"
                      >
                        <MdDelete />
                      </td>
                    )}
                  </div>
                </tr>
              </>
            );
          })}
        </table>
      </div>
      <Modal show={formShow} onHide={handleClose}>
        <Modal.Header closeButton className="fw-bold">
          Add Service
        </Modal.Header>

        <form
          action=""
          onSubmit={handleSubmit}
          className="p-3 d-flex flex-column justify-content-center"
        >
          <fieldset className="d-flex flex-column mt-2">
            <label htmlFor="serviceName" className="fw-bold">
              Service Name
            </label>
            <input
              type="text"
              id="serviceName"
              className="p-2 rounded-2 border border-1"
              placeholder="Enter service name"
              onChange={handleServiceInfoChange}
            />
          </fieldset>
          <fieldset className="d-flex flex-column mt-2">
            <label htmlFor="fee" className="fw-bold">
              Fee
            </label>
            <input
              type="number"
              id="fee"
              className="p-2 rounded-2 border border-1"
              placeholder="Enter fee amount"
              onChange={handleServiceInfoChange}
            />
          </fieldset>
          <fieldset className="d-flex flex-column mt-2">
            <label htmlFor="gst" className="fw-bold">
              GST
            </label>
            <input
              type="number"
              id="gst"
              className="p-2 rounded-2 border border-1"
              placeholder="Enter GST amount"
              onChange={handleServiceInfoChange}
            />
          </fieldset>
          <fieldset className="d-flex flex-column mt-2">
            <label htmlFor="total" className="fw-bold">
              Total
            </label>
            <input
              type="number"
              id="total"
              className="p-2 rounded-2 border border-1"
              placeholder="Enter total amount"
              onChange={handleServiceInfoChange}
            />
          </fieldset>
          <button type="submit" className="p-2 rounded-2 w-25 mt-2 mx-auto">
            Submit
          </button>
        </form>
      </Modal>
    </div>
  );
}

export default FeeStructureTables;
