import { Routes, Route, useLocation, Outlet, Navigate } from "react-router-dom";
import { useEffect, useContext } from "react";
import Home from "../Pages/Home";
import StudyAbroad from "../Pages/StudyAbroad";
import Blog from "../Pages/Blog";
import Gre from "../Pages/Gre";
import Ielts from "../Pages/Ielts";
import Sat from "../Pages/Sat";
import Toefl from "../Pages/Toefl";
import Duolingo from "../Pages/Duolingo";
import Pte from "../Pages/Pte";
import Team from "../Pages/Team";
import OurStory from "../Pages/OurStory";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import TermsAndConditions from "../Pages/TermsAndConditions";
import RefundPage from "../Pages/RefundPage";
import BlogPageM from "../Pages/BlogPageM";
import LoginPage from "../components/LoginPage/LoginPage";
import PostEditor from "../components/Posteditor/PostEditor";
import DraftsComponent from "../components/Drafts/DraftsComponent";
import Careers from "../Pages/Careers";
import JobDescription from "../Pages/JobDescription";
import ApplicationForm from "../components/CareerPage/ApplicationForm";
import JobEditor from "../components/JobEditor/JobEditor";
import { AuthContext } from "../App";
import CoursesPage from "../Pages/CoursesPage";
import UserLogin from "../Pages/UserLogin";
// import VisitsPage from "../Pages/VisitsPage";
import DestinationPage from "../Pages/DestinationPage";
import ImageEditor from "../components/Study Abroad/ImageEditor/ImageEditor";
import Resources from "../components/Study Abroad/Resources/Resources";
// import Announcements from "../components/Study Abroad/Announcements";
import withCopyPasteDisabled from "../components/CopyPasteDisable/CopyPasteDisable";
import PartnersPage from "../components/PartnersPage/partnersPage";
import NotFound from "../components/NotFoundpage/NotFound";

//country pages
import USA from "../components/Study Abroad/CountryPages/USA";
import Uk from "../components/Study Abroad/CountryPages/Uk";
import IVYLeaguePage from "../components/Study Abroad/CountryPages/IVYUniversitiesPage";
import ServiceDescription from "../components/Study Abroad/ServiceDescriptionPages/ServiceDescription";
import Australia from "../components/Study Abroad/CountryPages/Australia";
import Canada from "../components/Study Abroad/CountryPages/Canada";
import Ireland from "../components/Study Abroad/CountryPages/Ireland";
import Germany from "../components/Study Abroad/CountryPages/Germany";
import France from "../components/Study Abroad/CountryPages/France";
import Netherlands from "../components/Study Abroad/CountryPages/Netherlands";
import Newzealand from "../components/Study Abroad/CountryPages/Newzealand";
import Spain from "../components/Study Abroad/CountryPages/Spain";
import Switzerland from "../components/Study Abroad/CountryPages/Switzerland";
import Italy from "../components/Study Abroad/CountryPages/Italy";

//partnersPage
// import PaymentConfirmation from "../components/Payment/paymentConfirmation";
import PaymentSuccess from "../components/Payment/paymentSuccessfull";
import PaymentFailure from "../components/Payment/paymentFailure";

const HomeWithCopyPasteDisabled = withCopyPasteDisabled(Home);
const StudyAbroadWithCopyPasteDisabled = withCopyPasteDisabled(StudyAbroad);
const BlogWithCopyPasteDisabled = withCopyPasteDisabled(Blog);
const GreWithCopyPasteDisabled = withCopyPasteDisabled(Gre);
const SatWithCopyPasteDisabled = withCopyPasteDisabled(Sat);
const DuolingoWithCopyPasteDisabled = withCopyPasteDisabled(Duolingo);
const ToeflWithCopyPasteDisabled = withCopyPasteDisabled(Toefl);
const IeltsWithCopyPasteDisabled = withCopyPasteDisabled(Ielts);
const PteWithCopyPasteDisabled = withCopyPasteDisabled(Pte);
const TeamWithCopyPasteDisabled = withCopyPasteDisabled(Team);
const OurStoryWithCopyPasteDisabled = withCopyPasteDisabled(OurStory);
const CareersWithCopyPasteDisabled = withCopyPasteDisabled(Careers);
const PrivacyPolicyWithCopyPasteDisabled = withCopyPasteDisabled(PrivacyPolicy);
const TermsAndConditionsWithCopyPasteDisabled =
  withCopyPasteDisabled(TermsAndConditions);
const RefundPageWithCopyPasteDisabled = withCopyPasteDisabled(RefundPage);
const BlogPageMWithCopyPasteDisabled = withCopyPasteDisabled(BlogPageM);
const LoginPageWithCopyPasteDisabled = withCopyPasteDisabled(LoginPage);
const JobDescriptionWithCopyPasteDisabled =
  withCopyPasteDisabled(JobDescription);
const ApplicationFormWithCopyPasteDisabled =
  withCopyPasteDisabled(ApplicationForm);
const ImageEditorWithCopyPasteDisabled = withCopyPasteDisabled(ImageEditor);
// const CoursesPageWithCopyPasteDisabled = withCopyPasteDisabled(CoursesPage);
// const VisitsPageWithCopyPasteDisabled = withCopyPasteDisabled(VisitsPage);
const ResourcesWithCopyPasteDisabled = withCopyPasteDisabled(Resources);
const UserLoginWithCopyPasteDisable = withCopyPasteDisabled(UserLogin);
// const AnnouncementsWithCopyPasteDisabled = withCopyPasteDisabled(Announcements);

// import PrivateRoutes from "../Routes/PrivateRoute";
// import TestPrep from "../Pages/TestPrep";
// import Whatwedo from "../components/Home/Whatwedo";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function PrivateRoutes() {
  const { token } = useContext(AuthContext);
  return token ? <Outlet /> : <Navigate to="/blogs" />;
}

function NavbarPathConfig() {
  return (
    <div className="pathConfig">
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomeWithCopyPasteDisabled />} />
        <Route
          path="/student-guide"
          element={<StudyAbroadWithCopyPasteDisabled />}
        />
        <Route path="/blogs" element={<BlogWithCopyPasteDisabled />} />
        <Route path="/gre" element={<GreWithCopyPasteDisabled />} />
        <Route path="/sat" element={<SatWithCopyPasteDisabled />} />
        <Route path="/duolingo" element={<DuolingoWithCopyPasteDisabled />} />
        <Route path="/toefl" element={<ToeflWithCopyPasteDisabled />} />
        <Route path="/ielts" element={<IeltsWithCopyPasteDisabled />} />
        <Route path="/pte" element={<PteWithCopyPasteDisabled />} />
        <Route path="/Team" element={<TeamWithCopyPasteDisabled />} />
        <Route path="/OurStory" element={<OurStoryWithCopyPasteDisabled />} />
        <Route path="/Careers" element={<CareersWithCopyPasteDisabled />} />
        <Route path={`/`} element={<HomeWithCopyPasteDisabled />} />

        <Route
          path="/PrivacyPolicy"
          element={<PrivacyPolicyWithCopyPasteDisabled />}
        />
        <Route
          path="/TermsAndConditions"
          element={<TermsAndConditionsWithCopyPasteDisabled />}
        />
        <Route
          path="/payment-refund-policy"
          element={<RefundPageWithCopyPasteDisabled />}
        />
        <Route
          path="/blog/:title/:id"
          element={<BlogPageMWithCopyPasteDisabled />}
        />
        <Route path="/admin" element={<LoginPageWithCopyPasteDisabled />} />
        <Route path="/Careers" element={<CareersWithCopyPasteDisabled />} />
        <Route
          path="/careers/:title/:jobId"
          element={<JobDescriptionWithCopyPasteDisabled />}
        />
        <Route
          path="/applylink/:title/:id"
          element={<ApplicationFormWithCopyPasteDisabled />}
        />
        <Route
          path="/imageEditor"
          element={<ImageEditorWithCopyPasteDisabled />}
        />
        <Route path="/coursesInfo" element={<CoursesPage />} />
        {/* <Route path="/visits" element={<VisitsPage />} /> */}
        <Route path="/resources" element={<ResourcesWithCopyPasteDisabled />} />
        {/* <Route
          path="/Notifications"
          element={<AnnouncementsWithCopyPasteDisabled />}
        /> */}
        {/* <Route path="/destination" element={<HomeWithCopyPasteDisabled />} /> */}
        <Route path="/destination" element={<DestinationPage />} />

        <Route
          path={"/destination/Study-in-United-States-of-America"}
          element={<USA />}
        />
        <Route path={"/destination/Study-in-United-Kingdom"} element={<Uk />} />
        <Route
          path={"/destination/Study-in-Australia"}
          element={<Australia />}
        />
        <Route path={"/destination/Study-in-Canada"} element={<Canada />} />
        <Route path={"/destination/Study-in-Ireland"} element={<Ireland />} />
        <Route path={"/destination/Study-in-Germany"} element={<Germany />} />
        <Route path={"/destination/Study-in-France"} element={<France />} />
        <Route
          path={"/destination/Study-in-Netherlands"}
          element={<Netherlands />}
        />
        <Route
          path={"/destination/Study-in-Newzealand"}
          element={<Newzealand />}
        />
        <Route path={"/destination/Study-in-Spain"} element={<Spain />} />
        <Route
          path={"/destination/Study-in-New-zealand"}
          element={<Newzealand />}
        />
        <Route
          path={"/destination/Study-in-Switzerland"}
          element={<Switzerland />}
        />
        <Route path={"/destination/Study-in-Italy"} element={<Italy />} />
        <Route path={"/:country/universities"} element={<IVYLeaguePage />} />
        <Route
          path={"/:country/:serviceName/:serviceId"}
          element={<ServiceDescription />}
        />
        <Route
          path="/serviceId/:serviceId"
          element={<UserLoginWithCopyPasteDisable />}
        />
        <Route path="/paymentsuccess" element={<PaymentSuccess />} />
        <Route path="/paymentfailure" element={<PaymentFailure />} />
        <Route path="/partners" element={<PartnersPage />} />

        <Route path="/protected" element={<PrivateRoutes />}>
          <Route index element={<Navigate to="/protected/addPost" />} />
          <Route path="/protected/addPost" element={<PostEditor />} />
          <Route path="/protected/drafts" element={<DraftsComponent />} />
          <Route path="/protected/addJob" element={<JobEditor />} />
        </Route>
        <Route path="*" element={<NotFound />} />

        {/* <Route path="/testprep" element={<TestPrep />} /> */}
        {/* <Route path="/" exact element={<Whatwedo />} /> */}
        {/* <Route path="/Services" element={<Home />} /> */}
        {/* <Route path="/editPost" element={<PostEditor />} /> */}
      </Routes>
    </div>
  );
}
export default NavbarPathConfig;
