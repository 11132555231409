import React, { useState, useEffect, useContext } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./partnersPage.css";
import { FaPlus } from "react-icons/fa";
import PartnerList from "./partnerCard";
import axios from "axios";
import config from "../../Boot/config.json";
import PageEvent from "../../Assets/Page Event.jpeg";
import { AuthContext } from "../../App";

function PartnersPage() {
  const [show, setShow] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [data, setData] = useState([]);
  const { token } = useContext(AuthContext);

  const handleClose = () => {
    setShow(false);
    setPartnerData({
      logo: null,
      name: "",
      description: "",
    });
    setLogoPreview(null);
    setLogoName("No file choosen");
  };
  const handleShow = () => setShow(true);

  const [partnerData, setPartnerData] = useState({
    logo: null,
    name: "",
    description: "",
  });
  const [charCount, setCharCount] = useState(0);
  const [logoName, setLogoName] = useState("No file chosen");
  const [logoPreview, setLogoPreview] = useState(null);
  const charLimit = 250;

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "logo" && files[0]) {
      const file = files[0];
      setPartnerData((prevData) => ({
        ...prevData,
        logo: file,
      }));
      setLogoName(file.name);

      // Create a URL for the preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogoPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      if (name === "description") {
        if (value.length <= charLimit) {
          setPartnerData((prevData) => ({
            ...prevData,
            [name]: value,
          }));
          setCharCount(value.length);
        }
      } else {
        setPartnerData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("logo", partnerData.logo);
    formData.append("name", partnerData.name);
    formData.append("description", partnerData.description);

    axios
      .post(`${config.baseUrl}/Partners/createpartner`, formData, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setUpdateSuccess(!updateSuccess);
        setShow(false);
        setPartnerData({
          logo: null,
          name: "",
          description: "",
        });
        setLogoPreview(null);
        setLogoName("No file choosen");
      })
      .catch((err) => {
        console.error("Error:", err.response ? err.response.data : err.message);
      });
  };

  useEffect(() => {
    axios
      .get(`${config.baseUrl}/Partners/getpartners`)
      .then((response) => {
        if (response.data && response.data.length > 0) {
          setData(response.data);
        } else {
          console.error("No partner data received");
        }
      })
      .catch((error) => {
        console.error("Error fetching partner data:", error);
      });
  }, [updateSuccess]);

  return (
    <>
      <div className="partnersPage_Container d-flex justify-content-center">
        <h2 className="ourPartner_heading">Our Partners and Events</h2>
        {token && (
          <Button onClick={handleShow} className="custom_addPartnerButton">
            <FaPlus />
          </Button>
        )}
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Partner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="partner-form" onSubmit={handleSubmit}>
            <div className="file-upload-container">
              <label className="file-upload-label" htmlFor="logo">
                Logo
              </label>
              <input
                type="file"
                accept="image/*"
                id="logo"
                name="logo"
                onChange={handleChange}
                style={{ display: "none" }} // Hide the default file input
              />
              <span
                className="file-upload-button"
                onClick={() => document.getElementById("logo").click()}
              >
                Upload
              </span>
              <span className="file-placeholder">{logoName}</span>
            </div>
            {logoPreview && (
              <div className="logo-preview-container">
                <img
                  src={logoPreview}
                  alt="Logo Preview"
                  className="logo-preview"
                />
              </div>
            )}
            <label>
              University Name
              <input
                type="text"
                id="name"
                name="name"
                value={partnerData.name}
                onChange={handleChange}
                required
              />
            </label>
            <div className="description-container">
              <label htmlFor="description" className="description-label">
                Description
              </label>
              <span className="char-count">
                <span
                  className={`char-number ${
                    partnerData.description.length >= charLimit - 25
                      ? "char-count-red"
                      : ""
                  }`}
                >
                  {partnerData.description.length}
                </span>
                /{charLimit}
              </span>
            </div>
            <textarea
              id="description"
              name="description"
              value={partnerData.description}
              onChange={handleChange}
              rows="4"
              required
              className="textarea-custom"
            />
            <button type="submit">Submit</button>
          </form>
        </Modal.Body>
      </Modal>
      <PartnerList partnerData={data} />

      <div className="mb-4">
        <h2 className="text-center">Our Partner Events</h2>

        <div className="d-flex justify-content-center my-3">
          <img
            src={PageEvent}
            alt="Page academy event"
            className="pageEvent "
          />
        </div>
      </div>
    </>
  );
}

export default PartnersPage;
