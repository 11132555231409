import React from "react";
import Ielts2 from "../../../Assets/img36.jpg";
import Ielts3 from "../../../Assets/img38.jpg";
import Ielts4 from "../../../Assets/img41.jpg";
import Ielts5 from "../../../Assets/img43.jpg";
import Ielts6 from "../../../Assets/img45.jpg";
import Ielts7 from "../../../Assets/img47.jpg";
import Ielts8 from "../../../Assets/img49.jpg";
// import ieltsBanner from "../../../Assets/ian-schneider-TamMbr4okv4-unsplash.jpg";
import "./ielts-images.css";

function IEltImages() {
  const ieltsImgs = [
    {
      imgSrc: Ielts8,
      alt: "ielts academic regular demo (3days)",
    },
    {
      imgSrc: Ielts6,
      alt: "ielts academic test pack (90 days)",
    },
    {
      imgSrc: Ielts7,
      alt: "ielts academic live class (90 days)",
    },
    {
      imgSrc: Ielts4,
      alt: "ielts academic test class + live class + 100 days",
    },
    {
      imgSrc: Ielts5,
      alt: "ielts academic test class + live class (90 days)",
    },
    {
      imgSrc: Ielts2,
      alt: "ielts academic test pack & GRE (180 days)",
    },
    {
      imgSrc: Ielts3,
      alt: "ielts academic test pack + live class (90 days)",
    },
  ];
  return (
    <div>
      <div>
        {ieltsImgs.map((ieltsImg) => (
          <a href="https://testprep.confluenceedu.com/">
            <img
              src={ieltsImg.imgSrc}
              alt={ieltsImg.alt}
              className="ieltsimg1 mb-2"
            ></img>
          </a>
        ))}
      </div>
    </div>
  );
}

export default IEltImages;
