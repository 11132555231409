import React from "react";
import FeeStructureTables from "../FeeStructureTable";
import Services from "./Services.json";
import ItalyAtoV from "../../../Assets/countryImages/Italy A to V.jpg";
import "./countryImage.css";
function Italy() {
  const StudentServices = [
    {
      service: "Bachelors Admission Counseling Services",
      Fees: 30000,
      GST: 5400,
      Total: 35400,
    },
    {
      service: "Masters Admission Counseling Services",
      Fees: 25000,
      GST: 4500,
      Total: 29500,
    },
    {
      service: "Fashion Counseling",
      Fees: 120000,
      GST: 21600,
      Total: 141600,
    },
    {
      service: "Design Couseling",
      Fees: 120000,
      GST: 21600,
      Total: 141600,
    },
    {
      service: "Visit Visa Services",
      Fees: 30000,
      GST: 5400,
      Total: 35400,
    },
  ];

  return (
    <div className="bg-white d-flex justify-content-center flex-column align-items-center">
      <h1>Italy</h1>

      <img
        src={ItalyAtoV}
        alt="Ireland Admission to Visa"
        className="countryAtoVImg"
      />
      <FeeStructureTables
        countryName={"Ireland"}
        studentServices={StudentServices}
      />
    </div>
  );
}

export default Italy;
