import FeeStructureTables from "../FeeStructureTable";
// import Services from "./Services.json";
import AustraliaAtoV from "../../../Assets/countryImages/Australia A to V.jpg";
import "./countryImage.css";

function Australia() {
  // const { StudentServices } = Services;

  const StudentServices = [
    {
      service: "Bachelors Admission Counseling Services",
      Fees: 30000,
      GST: 5400,
      Total: 35400,
    },
    {
      service: "Masters Admission Counseling Services",
      Fees: 25000,
      GST: 4500,
      Total: 29500,
    },
    {
      service: "Visit Visa Services",
      Fees: 30000,
      GST: 5400,
      Total: 35400,
    },
  ];

  return (
    <div className="bg-white d-flex flex-column justify-content-center align-items-center">
      <img src={AustraliaAtoV} alt="" className="mx-auto countryAtoVImg" />
      <FeeStructureTables
        countryName={"Australia"}
        studentServices={StudentServices}
      />
    </div>
  );
}

export default Australia;
